import { CartOrderJson, CartPromotion } from "@/types/Cart.type";
import { CouponCheckJson } from "@/types/Coupon.type";
import { PromotionDiscountType, PromotionGroupType} from "@/types/Promotion.type";

export function mapCouponUpdate(
  coupons: CouponCheckJson[],
  products: CartOrderJson[],
  promotions: CartPromotion[]
) {
  let productsCouponUpdate: CartOrderJson[] = [];
  let couponBodyUpdate: CartPromotion[] = [];
  coupons.forEach((cou) => {
    if (cou.type === "product") {
      const productUpdate = products.reduce((pre: CartOrderJson[], product) => {

        const coupon = product.product_json.promotions.find(
          (pro) => pro.campaign_info.id === cou.coupon_campaign.id && pro.discount_type === PromotionDiscountType.PRODUCT
        );

        if (coupon && !product.promotions.some(pro=>pro.code === cou.code)) {
          const promotionsOld = product.promotions.filter(pro=>pro.promotion_detail.group !== PromotionGroupType.coupon);
             pre.push({
            ...product,
            promotions: [
              ...promotionsOld,
              {
                is_use: 1,
                promotion_detail: coupon,
                promotion_id: cou.coupon_campaign.id,
                sale_promotion_id: coupon.id,
                code: cou.code,
                id: cou.id,
              },
            ],
          });
        }

        return pre;
      }, []);
      productsCouponUpdate.push(...productUpdate);
      return;
    }

    if (cou.type === "order") {
      const data = promotions
        .filter((pro) => {
          return pro.sale_promotion_id === cou.coupon_campaign.id;
        }, [])
        .map((item) => ({
          ...item,
          code: cou.code,
          promotion_id: cou.coupon_campaign.id,
        }));
      couponBodyUpdate.push(...data);
    }
  });
  return {
    productsCouponUpdate,
    couponBodyUpdate,
  };
}
