export const Errors = {
  error_warehouse_invalid: "error_warehouse_invalid",
  error_store_warehouse_invalid: "error_store_warehouse_invalid",
  error_id_warehouse_invalid: "error_id_warehouse_invalid",
  error_item_quantity_invalid: "error_item_quantity_invalid",
  error_item_quantity_over: "error_item_quantity_over",
  error_warehouse_id_invalid: "error_warehouse_id_invalid",
  error_item_quantity_limit_sale: "error_item_quantity_limit_sale",
  error_product_id_invalid : "error_product_id_invalid"
};
