import { callApiV2 } from "@/lib/config/Api";
export async function getBrowserId() {
  const response = await callApiV2<string>("/customers/public/browser", "get" , {
    headers : {
      "X-User-Agent" : navigator.userAgent + ' ' + screen.width + '/' + screen.height + '/' + screen.pixelDepth,

    }
  });
  return response;
}
