import { callApiV2 } from "@/lib/config/Api";
import { CartId, CartJson } from "@/types/Cart.type";
import {
	CartRegisterJson,
	CustomerQuickRegisterJson,
} from "@/types/Customer.type";
import {
	PaymentDataUpdatePreOder,
	ResponCheckVnpay,
	ParamsGetURLQRCode,
} from "@/types/Payment.type";
import Helper from "@/utils/helper";

export async function paymentCheckVnpay(params: { [key: string]: string }) {
	const response = await callApiV2<ResponCheckVnpay>(
		`/orders/cart/public/checkvnpay/returnvnpay`,
		"get",
		{ params: params }
	);
	return response.data;
}

export async function updatePreOrder(
	order_id: CartId,
	dataUpdate: PaymentDataUpdatePreOder,
	isUser: boolean,
	type_sale?: "employee"
) {
	const dataPost = Helper.convertParams({
		...dataUpdate,
		type_sale: type_sale,
	});
	const response = await callApiV2<CartJson>(
		`/orders/cart${isUser ? "" : "/public"}/order/${order_id}`,
		"put",
		{ data: { ...dataPost } }
	);

	return response;
}
export async function updatePreOrderCart(
	order_id: CartId,
	dataUpdate: PaymentDataUpdatePreOder,
	isUser: boolean
) {
	const response = await callApiV2<CartJson>(
		`/orders/cart${isUser ? "" : "/public"}/update/${order_id}`,
		"put",
		{ data: { ...dataUpdate } }
	);

	return response;
}

export async function quickRegister(data: CustomerQuickRegisterJson) {
	const response = await callApiV2<CartRegisterJson>(
		`/customers/public/register`,
		"post",
		{
			data: data,
		}
	);

	return response;
}

export async function switchPreOrderToUser(
	id: CartId,
	data: { browser_id: string }
) {
	const response = await callApiV2<CartRegisterJson>(
		`/orders/cart/switch/${id} `,
		"put",
		{
			data: data,
		}
	);

	return response;
}

export function getImageQRCodeByOrder({
	transferAmount,
	transferContent,
	imgWidth = 150,
	imgHeight = 150,
}: ParamsGetURLQRCode) {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const params = {
		imgHeight: imgHeight.toString(),
		imgWidth: imgWidth.toString(),
		transferAmount: transferAmount.toString(),
		transferContent: decodeURIComponent(transferContent),
	};
	const u = new URL(`${baseURL}/utility/generate-vietqr?`);
	u.search = new URLSearchParams(params).toString();

	return u.toString();
}
