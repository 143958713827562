const Cart = {
	STATUS: {
		STATUS_CART: 12, // trạng thái mới tạo là giỏ hàng trống
		STATUS_USER_FINAL: 13, // user bấm thanh toán
		STATUS_ACCEPT: 14, // TRANG THÁI CHẤP thuận của admin

		STATUS_PROCESSING: 15, // xử lý
		STATUS_SHIPPING: 16, // đang chuyển hàng
		STATUS_SHIPPED: 17, // đã chuyển hàng
		STATUS_RETURNING: 18, // trả lại

		STATUS_COMPLETE: 19, // trạng thái đơn hoàn tất
		STATUS_CANCEL: 21, // trạng thái hủy
	},
	ERROR: {
		error_not_found: "Giỏ hàng không tồn tại",
		error_not_found_order: " id order không tìm thấy",
		error_customer_id_invalid: "tài khoảng này không hợp lệ",
		error_onwer: "tài khoảng không sở hữu order này ",
		error_store_id_invalid: "store_id không hợp lệ",
		error_order_detail_requeired: "details phải được truyền lên ",
		error_update_details_order: "lỗi update lại item trong order ",
		error_add_receipt: "không thể tạo phiếu xuất kho",
		error_update_order_cart: "lỗi ko thể update lại cart",
		error_status_order: " trạng thái của order không hợp lệ",

		error_shipping_address_required: "không có địa chỉ giao hàng ",
		error_shipping_phone_required: "không có số điện thoại ship",
		error_shipping_full_name_required: "không có người nhận",
		error_price_final_required: "không có số tiền tổng",
		error_price_sell_required: "không có số tiền hàng",
		error_full_name_required: "tên của người mua",
		error_delete_cart: "không thể xóa giỏ hàng",

		error_coupon_invalid: "coupon không họp lệ",
		error_discount_coupon: "prmotion coupon không họp lệ",

		error_promotion_invalid: "prmotion không họp lệ",
		error_discount_promotion: "giá giảm prmotion không họp lệ",
		error_price_final_invalid: "tổng tiền không họp lệ",
		error_total_payment_invalid: "tổng tiền thanh toán không họp lệ",
		error_product_id_invalid: "Sản phẩm không hợp lệ",

		error_payment_invalid: "lỗi payment không họp lệ",
		error_voucher_invalid: "lỗi voucher không họp lệ",
		error_voucher_status_invalid: "trạng thái voucher không hợp lệ",

		//mã lỗi thêm của update item
		error_promotion_require: "nếu có giảm giá phải truyền promotion lên",
		error_promotion_id_require: "truyền promotion id chương trình",
		error_id_require: "truyền sale promotion id",
		error_promotion_detail_require: "cần truyền thêm promotion json lên",
		error_price_discount_invalid: "tiền truyền không đúng",
		error_item_quantity_over: "error_item_quantity_over",
		error_promotion_time_invalid: "error_promotion_time_invalid",
		error_promotion_quantity_invalid: "error_promotion_quantity_invalid",
		error_promotion_subtotal_invalid: "error_promotion_subtotal_invalid",

		error_too_many_coupons: "error_too_many_coupons",
		error_coupon_subtotal_invalid : "error_coupon_subtotal_invalid"
	},
	ERROR_CODE: {
		error_item_quantity_invalid: "error_item_quantity_invalid",
		error_item_quantity_invalid_detail: "error_item_quantity_invalid_detail",
		error_price_invalid: "error_price_invalid",
		error_price_invalid_detail: "error_price_invalid_detail",
		error_product_allow_sale: "error_product_allow_sale",
		error_product_status: "error_product_status",
		error_item_quantity_limit_sale : "error_item_quantity_limit_sale"
	},
	POPUP: {
		cart_error_popup: "cart_error_popup",
		cart_coupon_popup: "cart_coupon_popup",
		add_cart_popup: "add_cart_popup",
		cart_converted_popup: "cart_converted_popup",
		cart_variant_popup: "cart_variant_popup",
		card_product_popup: "product-popup",
		event_popup: "event_popup",
		system_error: "system_error",
	},
	RESIZE: {
		SHOP_CART_ITEM: 600,
		SELECT_VARIANT: 28,
		SELECT_IMAGE: 85,
		HEADER_CART: 115,
	},
	PROMOTION_TYPE:{
		PROCESSING:1,
		PENDING:2,
		DONE:3,
	}
};

export default Cart;
