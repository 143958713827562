"ues client";
import { CartJson, CartOrderJson, CartTimingJson } from "@/types/Cart.type";
import { ProductJson } from "@/types/Product.type";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CouponCheckJson } from "@/types/Coupon.type";
import Local from "@/common/constants/local";
type InitialState = {
	cartLocal: CartJson | null;
	cartLocalId: string | null;
	cartDetail: CartJson | null;
	ProductByNow: ProductJson | null;
	loadingCart: boolean;
	loadingPayment: boolean;
	cartGlobalErrors: string[];
	selectedCartCoupon: CouponCheckJson[];
	// cartPreOrder: CartJson;
	productInCart: ProductJson[] | undefined;
	productOutOfStockInCartInCart: CartOrderJson[];
	productNotFoundInCartInCart: CartOrderJson[];
	recentProductAddToCart: ProductJson[];
	cartBrowserId: string;
	cartTiming: CartTimingJson;
	promotionCouponError: string[];
};

/////////////////////////

const initialState: InitialState = {
	cartLocal: null,
	cartLocalId: "",
	cartDetail: null,
	loadingCart: true,
	loadingPayment: false,
	cartGlobalErrors: [],
	productInCart: undefined,
	productNotFoundInCartInCart: [],
	productOutOfStockInCartInCart: [],
	recentProductAddToCart: [],
	cartBrowserId: "",
	cartTiming: { recheck_stock: 0, switch_cart: 0 },
	ProductByNow: null,
	selectedCartCoupon: [],
	promotionCouponError: [],
};
const CartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		setCartLocal: (state, action) => {
			state.cartLocal = action.payload;
		},
		setCartLocalId: (state, { payload }: { payload: string | null }) => {
			state.cartLocalId = payload;
			localStorage.setItem(
				Local.storage.cart_local_id,
				JSON.stringify(payload)
			);
		},
		setCartDetail: (state, action) => {
			state.cartDetail = action.payload;
		},
		setCartLoading: (state, action) => {
			state.loadingCart = action.payload;
		},
		setPaymentLoading: (state, action) => {
			state.loadingCart = action.payload;
		},
		setCartGlobalErrors: (state, action) => {
			state.cartGlobalErrors = action.payload;
		},
		setSelectedCartCoupon: (state, action) => {
			state.selectedCartCoupon = action.payload;
		},
		// setSelectedCartVoucher: (state, action) => {
		// 	state.selectedVoucher = action.payload;
		// },
		// setPreOrder: (state, action) => {
		// 	state.cartPreOrder = action.payload;
		// },
		setProductInCart: (state, action) => {
			state.productInCart = action.payload;
		},
		setProductOutOfStockInCart: (
			state,
			{ payload }: { payload: CartOrderJson[] }
		) => {
			state.productOutOfStockInCartInCart = [
				...state.productOutOfStockInCartInCart,
				...payload,
			];
		},
		setProductNotFoundInCart: (state, action) => {
			state.productNotFoundInCartInCart = action.payload;
		},
		setRecentProduct: (state, action) => {
			state.recentProductAddToCart = action.payload;
		},
		setCartBrowserId: (state, action) => {
			state.cartBrowserId = action.payload;
		},
		setCartTiming: (state, action) => {
			state.cartTiming = action.payload;
		},
		setProductByNow: (state, action) => {
			state.ProductByNow = action.payload;
		},
		setPromtionCouponError: (state, { payload }: { payload: string[] }) => {
			state.promotionCouponError = payload;
		},
	},
});

export const {
	setCartLocal,
	setCartDetail,
	setCartLocalId,
	setCartLoading,
	setPaymentLoading,
	setCartGlobalErrors,
	setSelectedCartCoupon,
	// setSelectedCartVoucher,
	// setPreOrder,
	setRecentProduct,
	setCartBrowserId,
	setCartTiming,
	setProductByNow,
	setProductOutOfStockInCart,
	setProductNotFoundInCart,
	setProductInCart,
	setPromtionCouponError
} = CartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCart = (state: RootState) => state.CartReducer.cartLocal;

export default CartSlice.reducer;
