"use client";

import { useQuery } from "@tanstack/react-query";
import { getSettingV2 } from "../api/shop/setting";
import SettingApi from "@/common/constants/setting";

export default function useStoreId(initialData?: number) {
	return useQuery<number>({
		queryKey: [SettingApi.KEY.k_setting_id_store_web_shop],
		queryFn: async () => {
			try {
				const { data } = await getSettingV2(
					SettingApi.KEY.k_setting_id_store_web_shop
				);
				return data.value as number;
			} catch (error) {
				return 0;
			}
		},
		staleTime: 1000 * 60 * 5,
		initialData: initialData || 0,
		placeholderData: 0,
		enabled: !initialData,
	});
}
