import { callApiV2 } from "@/lib/config/Api";
import { CartPayJson } from "@/types/Cart.type";
import { PaymentDataSubmit } from "@/types/Payment.type";

export async function checkoutAction(data: PaymentDataSubmit, user: boolean) {
    let dataProps: any = { ...data };
    delete dataProps.order_id;
    let postData = {
      ...dataProps,
    };
    return await callApiV2<CartPayJson>(
      `/orders/cart${user ? "" : "/public"}/pay/${data.order_id}`,
      "put",
      {
        data: postData,
        timeout : 1000 * 60
      }
    );

}
