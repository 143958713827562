"use client"
import { useQuery } from '@tanstack/react-query'
import Local from '@/common/constants/local'
import { getBrowserId } from '../api/shop/shop'
import Helper from '@/utils/helper'

export default function useBrowerId() {
  return useQuery({
    queryKey: [Local.storage.browser_id],
    queryFn: async () => {
      try {
        const defaultB = localStorage.getItem(Local.storage.browser_id)
        if(defaultB){
          return defaultB
        }
        const browser_id = (await getBrowserId()).data
        localStorage.setItem(Local.storage.browser_id, browser_id)
        return browser_id
      } catch (error) {
        throw error
      }
    },
    staleTime: Infinity,

  })
}
